<template>
  <div class="rounded p-3 mb-3" v-background-3>
    <h6>Effectifs</h6>

    <base-select
      class="mb-1"
      inputText="Choisir dans la liste"
      v-model.number="filter_id"
      :options="[
        { id: 0, description: 'Effectif total' },
        { id: 1, description: 'Effectif par age' },
        { id: 2, description: 'Effectif par sexe' },
        { id: 3, description: 'Effectif par qualification' },
        { id: 4, description: 'Effectif par type de contrat' },
        { id: 5, description: 'Effectif handicapés' },
        { id: 6, description: 'Ancienneté' },
      ]"
      :modeIOS="true"
      @input="filter2_id = 0"
    ></base-select>
    <base-select
      v-if="filter_id !== 2"
      inputText="Filter par H/F"
      v-model.number="filter2_id"
      :options="[
        { id: 0, description: 'Tous' },
        { id: 1, description: 'Hommes' },
        { id: 2, description: 'Femmes' },
      ]"
      :modeIOS="true"
    ></base-select>
    <apexchart ref="chart" type="area" height="460" :options="chartOptions" :series="series.data"></apexchart>
  </div>
</template>

<script>
import BaseSelect from "@/components/bases/Select.vue";

export default {
  data() {
    return {
      //series: [],
      labels: [],
      filter_id: 0,
      filter2_id: 0,
    };
  },
  components: { BaseSelect },

  props: ["collaborateurs", "collaborateursData", "selectedSocieteId"],
  methods: {},
  mounted() {},
  computed: {
    series() {
      let debut = this.$moment().endOf("month").subtract(2, "year");
      let fin = this.$moment().endOf("month");
      let dataTotal = [];
      let dataAge1 = [];
      let dataAge2 = [];
      let dataAge3 = [];
      let dataAge4 = [];
      let dataQualification = [];
      let dataTypeContrat = [];
      let dataRQTH = [];
      let dataH = [];
      let dataF = [];
      let labels = [];
      let dataAnciennete = {
        data1: [],
        data2: [],
        data3: [],
        data4: [],
        data5: [],
        data6: [],
        data7: [],
        data8: [],
      };

      if (!this.collaborateursData) return {};

      if (
        this.collaborateursData &&
        this.collaborateursData.contratStatus &&
        this.collaborateursData.contratStatus.length > 0
      )
        this.collaborateursData.contratStatus.forEach((statut) => {
          dataQualification.push({
            statut_id: statut.id,
            name: statut.description,
            data: [],
          });
        });

      if (
        this.collaborateursData &&
        this.collaborateursData.contratTypes &&
        this.collaborateursData.contratTypes.length > 0
      )
        this.collaborateursData.contratTypes.forEach((type) => {
          dataTypeContrat.push({
            type_id: type.id,
            name: type.description,
            data: [],
          });
        });

      let collabs = this.collaborateurs;

      if (this.filter2_id > 0) collabs = collabs.filter((collab) => collab.collaborateur_sexe_id == this.filter2_id);

      while (debut < fin) {
        dataTotal.push(
          collabs.filter((collab) =>
            collab.collaborateur_contrats_all.some(
              (contrat) =>
                !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                this.$moment(contrat.contrat_date_debut) <= debut &&
                (contrat.contrat_date_fin == "" ||
                  !contrat.contrat_date_fin ||
                  this.$moment(contrat.contrat_date_fin) >= debut)
            )
          ).length
        );

        dataAge1.push(
          collabs.filter(
            (collab) =>
              collab.collaborateur_age <= 18 &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAge2.push(
          collabs.filter(
            (collab) =>
              collab.collaborateur_age > 18 &&
              collab.collaborateur_age <= 25 &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAge3.push(
          collabs.filter(
            (collab) =>
              collab.collaborateur_age > 25 &&
              collab.collaborateur_age <= 40 &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAge4.push(
          collabs.filter(
            (collab) =>
              collab.collaborateur_age > 40 &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataH.push(
          collabs.filter(
            (collab) =>
              collab.collaborateur_sexe_id == 1 &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataF.push(
          collabs.filter(
            (collab) =>
              collab.collaborateur_sexe_id == 2 &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataRQTH.push(
          collabs.filter(
            (collab) =>
              collab.collaborateur_handicape == 1 &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data1.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 1 &&
              debut.diff(this.$moment(collab.anciennete), "year", true) < 2 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data2.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 2 &&
              debut.diff(this.$moment(collab.anciennete), "year", true) < 3 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data3.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 3 &&
              debut.diff(this.$moment(collab.anciennete), "year", true) < 4 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data4.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 4 &&
              debut.diff(this.$moment(collab.anciennete), "year", true) < 5 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data5.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 5 &&
              debut.diff(this.$moment(collab.anciennete), "year", true) < 6 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data6.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 6 &&
              debut.diff(this.$moment(collab.anciennete), "year", true) < 7 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data7.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 7 &&
              debut.diff(this.$moment(collab.anciennete), "year", true) < 8 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataAnciennete.data8.push(
          collabs.filter(
            (collab) =>
              debut.diff(this.$moment(collab.anciennete), "year", true) >= 8 &&
              this.$moment(collab.anciennete) < debut &&
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
          ).length
        );

        dataQualification.forEach((d) => {
          d.data.push(
            collabs.filter((collab) =>
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  contrat.contrat_version_all.some((version) => version.contrat_statut_id == d.statut_id) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
            ).length
          );
        });

        dataTypeContrat.forEach((d) => {
          d.data.push(
            collabs.filter((collab) =>
              collab.collaborateur_contrats_all.some(
                (contrat) =>
                  !contrat.contrat_version_all.some((c) => c.contrat_siret.siret_societe_id !== this.selectedSocieteId) &&
                  contrat.contrat_version_all.some((version) => version.contrat_type_id == d.type_id) &&
                  this.$moment(contrat.contrat_date_debut) <= debut &&
                  (contrat.contrat_date_fin == "" ||
                    !contrat.contrat_date_fin ||
                    this.$moment(contrat.contrat_date_fin) >= debut)
              )
            ).length
          );
        });

        labels.push(debut.format("MMMM YYYY"));
        debut.add(1, "month").endOf("month");
      }

      let output = {};

      if (this.filter_id == 0)
        return {
          labels: labels,
          data: [{ name: "effectif", data: dataTotal }],
        };

      if (this.filter_id == 1)
        return {
          labels: labels,
          data: [
            { name: "Moins de 18 ans", data: dataAge1, color: "#dc3545" },
            { name: "Entre 18 et 25 ans", data: dataAge2, color: "#007bff" },
            { name: "Entre 25 et 40 ans", data: dataAge3, color: "#28a745" },
            { name: "Plus de 40 ans", data: dataAge4, color: "#6f42c1" },
          ],
        };

      if (this.filter_id == 2)
        return {
          labels: labels,
          data: [
            { name: "Homme", data: dataH, color: "#007bff" },
            { name: "Femme", data: dataF, color: "#e83e8c" },
          ],
        };

      if (this.filter_id == 3)
        return {
          labels: labels,
          data: dataQualification,
        };

      if (this.filter_id == 4)
        return {
          labels: labels,
          data: dataTypeContrat,
        };

      if (this.filter_id == 5)
        return {
          labels: labels,
          data: [{ name: "handicapés", data: dataRQTH, color: "#007bff" }],
        };

      if (this.filter_id == 6)
        return {
          labels: labels,
          data: [
            { name: "1 ans", data: dataAnciennete.data1, color: "#dc3545" },
            { name: "2 ans", data: dataAnciennete.data2, color: "#007bff" },
            { name: "3 ans", data: dataAnciennete.data3, color: "#28a745" },
            { name: "4 ans", data: dataAnciennete.data4, color: "#6f42c1" },
            { name: "5 ans", data: dataAnciennete.data5, color: "#e83e8c" },
            { name: "6 ans", data: dataAnciennete.data6, color: "#fd7e14" },
            { name: "7 ans", data: dataAnciennete.data7, color: "#20c997" },
            { name: "8 ans", data: dataAnciennete.data8, color: "#17a2b8" },
          ],
        };

      return output;
    },
    chartOptions() {
      if (!this.collaborateurs) return {};
      return {
        colors: ["#EB9D00"],
        chart: {
          background: "transparent",
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: "zoom",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
        },
        stroke: {
          width: 4,
          curve: "smooth",
        },
        xaxis: {
          categories: this.series.labels,
        },
        fill: {
          opacity: 0.8,
        },
        markers: {
          size: 4,
          strokeColors: "#fff",
          strokeWidth: 0,
          hover: {
            size: 7,
          },
        },
        legend: {
          show: true,
          position: "right",
          showForZeroSeries: true,
          itemMargin: {
            horizontal: 0,
            vertical: 3,
          },
          markers: {
            width: 10,
            height: 10,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " collaborateurs";
            },
          },
        },
      };
    },
  },
};
</script>
