<template>
  <div class="rounded p-3 mb-3" v-background-3>
    <h4>Egalité homme femme</h4>
    <p class="m-0">Salaires moyens par catégorie professionnelle</p>
    <div class="p-3">
      <table class="table table-responsive-lg border" v-background-3 v-color-1>
        <thead>
          <tr>
            <th scope="col" class="text-muted">Evaluation sur {{ collaborateursFiltered.length }} contrats</th>
            <th scope="col">Homme</th>
            <th scope="col">Femme</th>
            <th scope="col">Différence</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-left" v-for="statut in collaborateursData.contratStatus" :key="statut.id">
            <td class="text-left" scope="row">{{ statut.description }}</td>
            <td>
              {{ salaireMoyenH[statut.id] }} <span class="text-muted">({{ salaireMoyenH[statut.id + "_nb"] }})</span>
            </td>
            <td>
              {{ salaireMoyenF[statut.id] }} <span class="text-muted">({{ salaireMoyenF[statut.id + "_nb"] }})</span>
            </td>
            <td>{{ (salaireMoyenH[statut.id] - salaireMoyenF[statut.id]).toFixed(0) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: ["collaborateursData", "collaborateursLastContrats", "selectedSocieteId"],
  computed: {
    collaborateursFiltered() {
      return this.collaborateursLastContrats.filter((collab) => collab.contrat_siret.siret_societe_id === this.selectedSocieteId);
    },
    salaireMoyenH() {
      const contrats = this.collaborateursFiltered;
      const statuts = this.collaborateursData.contratStatus;
      let output = [];
      let cpt = 0;
      let moy = 0;
      let nbrContrat = 0;

      if (!statuts || !contrats) return [];

      statuts.forEach((statut) => {
        const d = contrats.filter(
          (contrat) => contrat.contrat_statut_id == statut.id && contrat.collaborateur_sexe_id == 1
        );
        cpt = 0;
        moy = 0;
        nbrContrat = 0;

        d.forEach((dd) => {
          moy = moy + dd.contrat_brut_annuel;
          cpt++;
          nbrContrat++;
        });

        if (cpt == 0) cpt = 1;
        output[statut.id] = (moy / cpt).toFixed(2);
        output[statut.id + "_nb"] = nbrContrat;
      });

      return output;
    },

    salaireMoyenF() {
      const contrats = this.collaborateursFiltered;
      const statuts = this.collaborateursData.contratStatus;
      let output = [];
      let cpt = 0;
      let moy = 0;
      let nbrContrat = 0;

      if (!statuts || !contrats) return [];

      statuts.forEach((statut) => {
        const d = contrats.filter(
          (contrat) => contrat.contrat_statut_id == statut.id && contrat.collaborateur_sexe_id == 2
        );
        cpt = 0;
        moy = 0;
        nbrContrat = 0;

        d.forEach((dd) => {
          moy = moy + dd.contrat_brut_annuel;
          cpt++;
          nbrContrat++;
        });

        if (cpt == 0) cpt = 1;

        output[statut.id] = (moy / cpt).toFixed(2);
        output[statut.id + "_nb"] = nbrContrat;
      });

      return output;
    },
  },
};
</script>
